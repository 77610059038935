import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import firebase from 'firebase/compat/app';
import { Trans, useTranslation } from "react-i18next";
import { setCurrentScreen } from '../../core/slice';
import SignInButton from '../../components/signInButton';
import GoogleIcon from '../../assets/images/logos/google.svg';
import MicrosoftIcon from '../../assets/images/logos/microsoft.svg';
import CellPhoneIcon from '../../assets/images/logos/phone.svg';
import HeaderText from '../../components/headerText';
import SubHeaderText from '../../components/subHeaderText';

function SignInButtons() {
  const dispatch = useDispatch();
  const auth = (useSelector((state) => state.projectData.auth));
  const { t } = useTranslation();

  return (
    <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center gap-4">
      <div className="d-flex flex-column gap-1 text-center">
        <HeaderText>
          {t('WELCOME')}
        </HeaderText>
        <SubHeaderText>
          <Trans
            i18nKey="SIGN_IN_INFO_MSG"
            values={{ weoll: 'Weoll' }}
            components={{
              1: <span className="text-center" style={{ fontWeight: '500' }}></span>,
              2: <br />
            }}
          >
          </Trans>
        </SubHeaderText>
      </div>
      <div className="d-flex flex-column gap-2">
        <SignInButton icon={GoogleIcon} click={() => {
          // dispatch(setCurrentScreen('loading'));
          const provider = new firebase.auth.GoogleAuthProvider();
          auth.signInWithRedirect(provider);
        }}>
          {t('GOOGLE_SIGN_IN')}
        </SignInButton>
        <SignInButton icon={MicrosoftIcon} click={() => {
          // dispatch(setCurrentScreen('loading'));
          const provider = new firebase.auth.OAuthProvider("microsoft.com");
          auth.signInWithRedirect(provider);
        }}>
          {t('MICROSOFT_SIGN_IN')}
        </SignInButton>
        <SignInButton icon={CellPhoneIcon} click={() => dispatch(setCurrentScreen('smsLogin'))}>
          {t('PHONE_SIGN_IN')}
        </SignInButton>
      </div>
    </div>
  );
}

export default SignInButtons;